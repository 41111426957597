import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { MainChat } from './components/pages/MainChat';
import { SecondChat } from './components/pages/SecondChat';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app">
        <nav className="navigation">
          <ul>
            <li><Link to="/">Assistant Principal</Link></li>
            <li><Link to="/second-chat">Second Assistant</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<MainChat />} />
          <Route path="/second-chat" element={<SecondChat />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
